import React, { Component } from "react";
import "./navbar.scss";

export default class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: "Home",
    };
  }

  MoveHeader() {
    const hdr = document.querySelector(".s-header");
    const hero = document.querySelector("#hero");
    let triggerHeight;

    if (!(hdr && hero)) return;

    setTimeout(function () {
      triggerHeight = hero.offsetHeight - 170;
    }, 300);

    window.addEventListener("scroll", function () {
      let loc = window.scrollY;

      if (loc > triggerHeight) {
        hdr.classList.add("sticky");
      } else {
        hdr.classList.remove("sticky");
      }

      if (loc > triggerHeight + 20) {
        hdr.classList.add("offset");
      } else {
        hdr.classList.remove("offset");
      }

      if (loc > triggerHeight + 150) {
        hdr.classList.add("scrolling");
      } else {
        hdr.classList.remove("scrolling");
      }
    });
  }

  MobileMenu() {
    const toggleButton = document.querySelector(".s-header__menu-toggle");
    const headerNavWrap = document.querySelector(".s-header__nav-wrap");
    const siteBody = document.querySelector("body");

    if (!(toggleButton && headerNavWrap)) return;

    toggleButton.addEventListener("click", function (event) {
      event.preventDefault();
      toggleButton.classList.toggle("is-clicked");
      siteBody.classList.toggle("menu-is-open");
    });

    headerNavWrap.querySelectorAll(".s-header__nav a").forEach(function (link) {
      link.addEventListener("click", function (evt) {
        if (window.matchMedia("(max-width: 800px)").matches) {
          toggleButton.classList.toggle("is-clicked");
          siteBody.classList.toggle("menu-is-open");
        }
      });
    });

    window.addEventListener("resize", function () {
      if (window.matchMedia("(min-width: 801px)").matches) {
        if (siteBody.classList.contains("menu-is-open"))
          siteBody.classList.remove("menu-is-open");
        if (toggleButton.classList.contains("is-clicked"))
          toggleButton.classList.remove("is-clicked");
      }
    });
  }

  ScrollSpy() {
    const sections = document.querySelectorAll(".target-section");

    window.addEventListener("scroll", navHighlight);

    function navHighlight() {
      let scrollY = window.pageYOffset;

      sections.forEach(function (current) {
        const sectionHeight = current.offsetHeight;
        const sectionTop = current.offsetTop - 50;
        const sectionId = current.getAttribute("id");

        if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          document
            .querySelector(".s-header__nav a[href*=" + sectionId + "]")
            .parentNode.classList.add("current");
        } else {
          document
            .querySelector(".s-header__nav a[href*=" + sectionId + "]")
            .parentNode.classList.remove("current");
        }
      });
    }
  }

  componentDidMount() {
    this.MoveHeader();
    this.MobileMenu();
    this.ScrollSpy();
  }


  render() {
    return (
      <div>
        <header className="s-header">
          <div className="s-header-container">
            <div className="row s-header__nav-wrap">
              <nav className="s-header__nav">
                <ul>
                  <li className="current">
                    <a className="smoothscroll" href="#hero">
                      Home
                    </a>
                  </li>
                  <li>
                    <a className="smoothscroll" href="#about">
                      About
                    </a>
                  </li>
                  <li>
                    <a className="smoothscroll" href="#achievements">
                      Achievements
                    </a>
                  </li>
                  <li>
                    <a className="smoothscroll" href="#portfolio">
                      Portfolio
                    </a>
                  </li>
                  <li>
                    <a className="smoothscroll" href="#shop">
                      Shop
                    </a>
                  </li>
                  <li>
                    <a className="smoothscroll" href="#contact">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <a className="s-header__menu-toggle" href="#0" title="Menu">
            <span className="s-header__menu-icon" />
          </a>
        </header>
      </div>
    );
  }
}
