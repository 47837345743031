import React from "react";
import { Row, Col } from "reactstrap";
import InfoCard from "../Card/InfoCard";

import "./portfolio.scss";

export default function Portfolio() {
  return (
    <div>
      <section id="portfolio" className="portfolio target-section">
      
          <div className="row">
            <h3 className="portfolio-title">Portfolio</h3>
          </div>
          <Row className="portfolio-row desktop">
            <Col xs="6" sm="4">
              <InfoCard
                title="UAE Warrior"
                description="Ali's Great Win"
                image="images/uae_warrior.jpeg"
                link="https://www.youtube.com/watch?v=WDPOA25vLNw"
              />
            </Col>
            <Col xs="6" sm="4">
              <InfoCard
                title="UFC"
                description="Ali's UFC highlights"
                image="images/ufc.jpeg"
                link="https://www.youtube.com/watch?v=BCDQoiVvIMU"
              />
            </Col>
            <Col xs="6" sm="4">
              <InfoCard
                title="UFC"
                description="Ali vs Tony Kelly "
                image="images/ufc_2.jpeg"
                link="https://www.youtube.com/watch?v=vbGwYU9BHU0"
              />
            </Col>
          </Row>
          <Row className="portfolio-row desktop">
            <Col xs="6" sm="4">
              <InfoCard
                title="Phoenix FC"
                description="Ali vs Philippe Massoud"
                image="images/phoenix_fc2.jpeg"
                link="https://www.youtube.com/watch?v=EqWNuSDIFEU"
              />
            </Col>
            <Col xs="6" sm="4">
              <InfoCard
                title="Phoenix FC"
                description="Ali vs Jaures Dea "
                image="images/phoenix_fc.jpeg"
                link="https://www.youtube.com/watch?v=56oTZLuE5SU"
              />
            </Col>
            <Col xs="6" sm="4">
              <InfoCard
                title="Brave FC"
                description="Pre-fight preparation"
                image="images/brave_fc.jpeg"
                link="https://www.youtube.com/watch?v=2byHz6WraZY"
              />
            </Col>
          </Row>

          <Row className="portfolio-row mobile">
            <Col xs="12" sm="6">
              <InfoCard
                title="UAE Warrior"
                description="Ali's Great Win"
                image="images/uae_warrior.jpeg"
                link="https://www.youtube.com/watch?v=WDPOA25vLNw"
              />
            </Col>
            <Col xs="12" sm="6">
              <InfoCard
                title="UFC"
                description="Ali's UFC highlights"
                image="images/ufc.jpeg"
                link="https://www.youtube.com/watch?v=BCDQoiVvIMU"
              />
            </Col>
            <Col xs="2" sm="6">
              <InfoCard
                title="UFC"
                description="Ali vs Tony Kelly "
                image="images/ufc_2.jpeg"
                link="https://www.youtube.com/watch?v=vbGwYU9BHU0"
              />
            </Col>
            <Col xs="12" sm="6">
              <InfoCard
                title="Phoenix FC"
                description="Ali vs Philippe Massoud"
                image="images/phoenix_fc2.jpeg"
                link="https://www.youtube.com/watch?v=EqWNuSDIFEU"
              />
            </Col>
            <Col xs="12" sm="6">
              <InfoCard
                title="Phoenix FC"
                description="Ali vs Jaures Dea "
                image="images/phoenix_fc.jpeg"
                link="https://www.youtube.com/watch?v=56oTZLuE5SU"
              />
            </Col>
            <Col xs="12" sm="6">
              <InfoCard
                title="Brave FC"
                description="Pre-fight preparation"
                image="images/brave_fc.jpeg"
                link="https://www.youtube.com/watch?v=2byHz6WraZY"
              />
            </Col>
          </Row>
      </section>
    </div>
  );
}
