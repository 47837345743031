import Hero from './components/Hero/Hero';
import Navbar from './components/Navbar/Navbar';
import About from './components/About/About';
import Portfolio from './components/Portfolio/Portfolio';
import Footer from './components/Footer/Footer';
import Shop from './components/Shop/Shop';
import Contact from './components/Contact/Contact';
import ChannelGallery from './components/Gallery/ChannelGallery';
import Achievements from './components/Achievements/Achievements';

import './App.scss';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Hero/>
      <About/>
      <Achievements/>
      <Portfolio/>
      <ChannelGallery/>
      <Shop/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
