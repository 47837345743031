import React from "react";
import { Card } from "antd";
import { YoutubeFilled } from "@ant-design/icons";
import "./card.scss";

const { Meta } = Card;

export default function InfoCard(props) {
  return (
    <div className="portfolio-container">
      <a className="portfolio-card" href={props.link} target="blank">
        <Card
          style={{ width: 240 }}
          cover={<img alt="example" src={props.image} />}
          actions={[<YoutubeFilled key="youtube" />]}
        >
          <Meta title={props.title} description={props.description} />
        </Card>
      </a>
    </div>
  );
}
