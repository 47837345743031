import React from "react";
import "./shop.scss";

export default function Shop() {
  return (
    <div>
      <section id="shop" className="shop target-section">
        <div class="row">
          <h3 className="shop-title">Shop</h3>
          <p class="shop-text">
            Coming Soon...
          </p>
        </div>
      </section>
    </div>
  );
}
