import React from "react";
import { Row, Col } from "reactstrap";

import "./achievements.scss";

export default function Achievements() {
  return (
    <div>
      <section id="achievements" className="s-resume target-section">
        <Row>
          <Col xs="12">
            <h3 className="achievements-title">Achievements</h3>
          </Col>
          <Col xs="12">
            <h5 className="achievements-title">20 Professional MMA Fights</h5>
          </Col>
        </Row>
        <Row className="first-row">
          <Col xs="12" md="6">
            <div className="row s-resume__section">
              <div className="column large-3 tab-12">
                <h3 className="section-header-allcaps">
                  <span className="resume-block__header-date">
                    2015-Present
                  </span>
                </h3>
              </div>
              <div className="column large-9 tab-12">
                <div className="resume-block">
                  <div className="resume-block__header">
                    <h4 className="h3">
                      Achievements of MMA local and international
                    </h4>
                    <p className="resume-block__header-meta">
                      <span>
                        <ul>
                          <li>18 MMA Fights</li>
                          <li>2 Fights with JAC</li>
                          <li>5 Fights with Desert Force</li>
                          <li>1 Fight with Kunlun</li>
                          <li>2 Fights with Phoenix</li>
                          <li>3 Fights with Brave</li>
                          <li>2 Fights with UFC</li>
                          <li>1 Fight UAE Warriors</li>
                          <li>1 Titan fc (world champion title fight)</li>
                          <li>1 UAE Warriors International (world champion title fight)</li>
                          <li>1 UAE Warriors Arabia (world champion title fight)</li>
                        </ul>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col xs="12" md="6">
            <div className="row s-resume__section">
              <div className="column large-3 tab-12">
                <h3 className="section-header-allcaps">
                  <span className="resume-block__header-date">2017-2018</span>
                </h3>
              </div>
              <div className="column large-9 tab-12">
                <div className="resume-block">
                  <div className="resume-block__header">
                    <h4 className="h3">Jujitsu</h4>
                    <p className="resume-block__header-meta">
                      <span>
                        <ul>
                          <li>2017 Silver</li>
                          <li>2017 Bronze</li>
                        </ul>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="first-row">
          <Col xs="12" md="6">
            <div className="row s-resume__section">
              <div className="column large-3 tab-12">
                <h3 className="section-header-allcaps">
                  <span className="resume-block__header-date">2015-2016</span>
                </h3>
              </div>
              <div className="column large-9 tab-12">
                <div className="resume-block">
                  <div className="resume-block__header">
                    <h4 className="h3">Global Kung Fu</h4>
                    <p className="resume-block__header-meta">
                      <span>
                        <ul>
                          <li>
                            2015 Bronze World Championship in Jakarta Indonesia
                          </li>
                          <li>2016 World Cup Bronze in China</li>
                        </ul>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs="12" md="6">
            <div className="row s-resume__section">
              <div className="column large-3 tab-12">
                <h3 className="section-header-allcaps">
                  <span className="resume-block__header-date">2009-2012</span>
                </h3>
              </div>
              <div className="column large-9 tab-12">
                <div className="resume-block">
                  <div className="resume-block__header">
                    <h4 className="h3">Boxing</h4>
                    <p className="resume-block__header-meta">
                      <span>
                        <ul>
                          <li>2009 Silver Championship Universities</li>
                          <li>2010 Golden Gendarmerie Championship</li>
                          <li>2011 Golden Army Championship</li>
                          <li>2012 Golden Public Security</li>
                        </ul>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="first-row">
          <Col xs="12" md="6">
            <div className="row s-resume__section">
              <div className="column large-3 tab-12">
                <h3 className="section-header-allcaps">
                  <span className="resume-block__header-date">2012-2017</span>
                </h3>
              </div>
              <div className="column large-9 tab-12">
                <div className="resume-block">
                  <div className="resume-block__header">
                    <h4 className="h3">Arabia Kung Fu</h4>
                    <p className="resume-block__header-meta">
                      <span>
                        <ul>
                          <li>2012 Arab Bronze</li>
                          <li>2013 Arab Silver</li>
                          <li>2014 Arab Gold</li>
                          <li>2015 Arab Gold</li>
                          <li>2016 Arab Gold</li>
                          <li>2017 Arab Gold</li>
                        </ul>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs="12" md="6">
            <div className="row s-resume__section">
              <div className="column large-3 tab-12">
                <h3 className="section-header-allcaps">
                  <span className="resume-block__header-date">2006-2018</span>
                </h3>
              </div>
              <div className="column large-9 tab-12">
                <div className="resume-block">
                  <div className="resume-block__header">
                    <h4 className="h3">Local Kung Fu</h4>
                    <p className="resume-block__header-meta">
                      <span>
                        <ul>
                          <li>Jordan Champion</li>
                        </ul>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="first-row">
          <Col xs="12" md="6">
            <div className="row s-resume__section">
              <div className="column large-3 tab-12">
                <h3 className="section-header-allcaps">
                  <span className="resume-block__header-date">2014</span>
                </h3>
              </div>
              <div className="column large-9 tab-12">
                <div className="resume-block">
                  <div className="resume-block__header">
                    <h4 className="h3">Middle East Kung Fu</h4>
                    <p className="resume-block__header-meta">
                      <span>
                        <ul>
                          <li>2014 Gold</li>
                        </ul>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </div>
  );
}
