import React from "react";

import "./contact.scss";

export default function Contact() {
  return (
    <div>
      <section id="contact" className="s-contact target-section">
        <div className="row s-contact__header">
          <div className="column large-12">
            <h3 className="section-header-allcaps">Contact Us</h3>
          </div>
        </div>
        <div className="row s-contact__content">
          <div className="column large-7 medium-12">
            <h4 className="huge-text">
            <b style={{color: 'white'}}>Email Us</b> now for an opportunity to join The Royal Fighter Team.
            </h4>
          </div>
          <div className="column large-4 medium-12">
            <div className="row contact-infos">
              <div className="column large-12 medium-6 tab-12">
                <div className="contact-block">
                  <h5 className="contact-block__header">Email</h5>
                  <p className="contact-block__content">
                    <a className="mailtoui" href="mailto:ali@alqaisimma.com">
                      ali@alqaisimma.com
                    </a>
                  </p>
                </div>
              </div>
              <div className="column large-12">
                <a
                  href="mailto:ali@alqaisimma.com"
                  className="mailtoui btn btn--primary h-full-width talk-btn"
                >
                  Let's Talk
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
