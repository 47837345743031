import React from "react";
import "./hero.scss";
import { TwitterOutlined, FacebookOutlined, InstagramOutlined } from '@ant-design/icons';

export default function Hero() {
  return (
    <section id="hero" className="s-hero target-section">
      <div className="s-hero__bg rellax" data-rellax-speed={-7} />
      <div className="row s-hero__content">
        <div className="column">
          <div className="s-hero__content-about">
            <h1>Ali Al-Qaisi.</h1>
              <h2 style={{color: 'white'}}>5x World Champion.</h2>
              <h3>
                <span>The first</span> Jordanian MMA fighter in the <span>UFC</span>.
              </h3>
              <div className="s-hero__content-social">
                <a href="https://twitter.com/alqaisimma" target="_blank" rel="noreferrer">
                  <i aria-hidden="true">
                  <TwitterOutlined />
                  </i>
                </a>
                <a href="https://www.facebook.com/alqaisimma" target="_blank" rel="noreferrer">
                  <i aria-hidden="true">
                  <FacebookOutlined />
                  </i>
                </a>
                <a href="https://www.instagram.com/alqaisimma/" target="_blank" rel="noreferrer">
                  <i aria-hidden="true">
                  <InstagramOutlined />
                  </i>
                </a>
              </div>
          </div>
        </div>
      </div>
      <div className="s-hero__scroll">
        <a href="#about" className="s-hero__scroll-link smoothscroll">
          <span className="scroll-arrow">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              style={{
                fill: "rgba(0, 0, 0, 1)",
                transform: "",
                msFilter: "",
              }}
            >
              <path d="M18.707 12.707L17.293 11.293 13 15.586 13 6 11 6 11 15.586 6.707 11.293 5.293 12.707 12 19.414z" />
            </svg>
          </span>
          <span className="scroll-text">Scroll Down</span>
        </a>
      </div>
    </section>
  );
}
