import React from "react";
import "./about.scss";

export default function About() {
  return (
    <div>
      <section id="about" className="s-about target-section">
        <div className="row">
          <div className="column large-12 tab-12 s-about__content">
            <h3>About Ali</h3>
            <p className="about-me-text">
              Ali AL-Qaisi is the first Jordanian MMA fighter to join the UFC.
              He is a part of the National Wushu Kung Fu Team in Jordan and a
              professional fighter in the American Combat gym (Miami, FL), and won numerous
              titles. Ali has won against Algeria, Italy, Qatar and Russia, has
              represented Jordan in the Olympic Games of 2017, and has been a
              solid part of The Desert Force organization as well as The Brave
              Fighting championship, the biggest championships in the Middle
              East.
            </p>
            <hr />
            <div className="row s-about__content-bottom">
              <div className="column large-12 w-1000-stack">
                <h3>Packages</h3>
                <p>
                  Business Packages <br />
                </p>
              </div>
              <div className="column w-1000-stack">
                <a
                  href="files/Qaisi_Packages.pdf"
                  target="_blank"
                  className="btn btn--download"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    style={{
                      fill: "rgba(0, 0, 0, 1)",
                      transform: "",
                      msFilter: "",
                    }}
                  >
                    <path d="M12 16L16 11 13 11 13 4 11 4 11 11 8 11z" />
                    <path d="M20,18H4v-7H2v7c0,1.103,0.897,2,2,2h16c1.103,0,2-0.897,2-2v-7h-2V18z" />
                  </svg>
                  Download Packages
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
