import React from "react";
import { Row, Col, Image } from "antd";

import "./gallery.scss";

export default function ChannelGallery() {
  return (
    <div className="gallery">
      <section className="gallery">
        <div className="row">
          <h3 className="gallery-title">Fight and Interview Streaming on</h3>
        </div>
        <Row>
          <Col xs="6" sm="4">
            <Image
              width={200}
              height={100}
              preview={false}
              src="images/channels/ufc_fight_pass.jpeg"
            />
          </Col>
          <Col xs="6" sm="4">
            <Image
              width={200}
              height={100}
              preview={false}
              src="images/channels/espn_plus.jpeg"
            />
          </Col>
          <Col xs="6" sm="4">
            <Image
              width={200}
              height={100}
              preview={false}
              src="images/channels/ad_sports.jpeg"
            />
          </Col>
          <Col xs="6" sm="4">
            <Image
              width={200}
              height={100}
              preview={false}
              src="images/channels/mbc_action.jpeg"
            />
          </Col>
          <Col xs="6" sm="4">
            <Image
              width={200}
              height={100}
              preview={false}
              src="images/channels/roya.png"
            />
          </Col>
          <Col xs="6" sm="4">
            <Image
              width={200}
              height={100}
              preview={false}
              src="images/channels/amman.png"
            />
          </Col>
          <Col xs="6" sm="4">
            <Image
              width={200}
              height={100}
              preview={false}
              src="images/channels/mamlaka.png"
            />
          </Col>
        </Row>
      </section>
    </div>
  );
}
